import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format, isValid } from "date-fns";
import COLORS from "../../../utils/colors";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");
// import { getSymbolFromCurrency } from 'currency-symbol-map';
// import { updatePaymentStatus } from "../../../redux/toolkit/sendFunds/operations";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { Spinner, Modal } from "react-bootstrap";
import InputTypeText from "../../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../../components/shared/Buttons/Basic";
import ModalHeader from "../../../components/shared/Modal/ModalHeader";
import Card from "react-bootstrap/Card";
import { Row, Col, } from "react-bootstrap";
import { dataaaaa } from "./data";
import "./index.css"
import { SearchFilter, DownloadFilter, FilterLines, PrintFilter } from "../../../constants/Images";
import { CiViewColumn } from "react-icons/ci";
import ToggleSwitch from "../ToggleButton";
import CustomPagination from "../CustomPagination";
import FundsTransferUpdateForm from "../../../pages/SendFunds/FundTransferUpdateForm";
import Accordion from 'react-bootstrap/Accordion'
import { PaymentVerifictionIcon } from "../../../constants/Images";

import SmsIcon from "@mui/icons-material/Sms";
import OtpInput from "../../../components/shared/OTP";
import CallIcon from "@mui/icons-material/Call";
import CustomIconButton from "../../../components/shared/Buttons/IconButton";

import {
    OTPVerification,
    SendOTP,
    SendOTPVoice,
  } from "../../../redux/toolkit/auth/operation";


export const CustomDataTable = ({ data,updatePaymentStatus,getRecPayments }: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
      const [currentPage, setCurrentPage] = useState<number>(0);
    const [code, setCode] = useState("");
    const [loading2, setLoading2] = useState(false);
    const [twofaErr, setTwofaErr] = useState("");
    const [show2fa, setShow2fa] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const dispatch = useDispatch<AppDispatch>();
    const toggle2faHandle = () => setShow2fa(!show2fa);
    const userId: any = localStorage.getItem("userId");
    const roles: any = localStorage.getItem("roles");

    const userPhoneNo: any = localStorage.getItem("userPhone");
    const [loadingOTP, setLoadingOTP] = useState(false);
    const [showOTPInput, setShowOTPInput] = useState(false);
    const [otpBtnText, setOtpBtnText] = useState("Send");
    const [otp, setOtp] = useState("");
    const onChange = (value: string) => setOtp(value);
    const [loadingOTPVerify, setLoadingOTPVerify] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState("");


    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        "root": {
                            padding: '10px',
                            fontFamily: 'Montserrat',
                            fontSize: 18,
                            // backgroundColor: '#CDCAC6',
                        }
                    }
                },
                MuiTypography: {
                    styleOverrides: {
                      root: {
                        fontFamily: 'Montserrat',
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#ADB6C0',
                      }
                    }
                  },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontFamily: 'Montserrat',
                            // fontSize:14,
                            fontWeight: "600",
                            textAlign: 'left',
                            paddingLeft: 0
                        },
                    }
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        root: {
                            fontWeight: "500 !important",
                        }

                    }
                },
                MuiToolbar: {
                  styleOverrides:{
                    root: {
                    display:'flex'
                  }}
                },
                MuiList:{
                    styleOverrides: {
                        root: {
                            display:"flex",
                            flexDirection:'column',
                            background:'#ffffff',
                        }
                    }
                },
                MUIDataTableFilter:{
                    styleOverrides:{
                      root:{fontFamily:'Montserrat'},
                      reset:{
                        // backgroundColor:"red",
                        '& button': { 
                          marginLeft:'10px',
                          paddingLeft:"6px",
                        }
                      },
                    }
                  },
                  MuiFormControl:{
                    styleOverrides:{
                      root:{
                        padding:'35px 20px 20px 20px'
                      }
                    }
                  },
            }
        });

    const columns = [
        {
            name: "recurringDate",
            label: "Date / Day",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <div style={{ minWidth: "max-content" }} >{
                        isValid(new Date(data[dataIndex]?.recurringDate)) ?
                            format(new Date(data[dataIndex]?.recurringDate), "yyyy-MM-dd") :
                            data[dataIndex]?.recurringDate
                    }
                    </div>
                }
            },
        },
        {
            name: "payment_reason",
            label: "Reason",
            options: { filter: true, sort: false },
        },
        {
            name: "payment_amount",
            label: "Amount",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <div style={{ minWidth: "55px" }} >{data[dataIndex]?.payment_amount}</div>
                }
            },
        },
        {
            name: "payment_currency",
            label: "Currency",
            options: { filter: true, sort: false },
        },
        {
            name: "recurringType",
            label: "Recurring Type",
            options: { filter: true, sort: false },
        },
        {
            name: "status",
            label: "Status",
            options: { filter: true, sort: false },
        },
        {
            name: "payment_amount",
            label: "Action",
            options: {
                filter: true, sort: false,
                customBodyRenderLite: (dataIndex: any) => {
                    return <ToggleSwitch onChangeHandler={() => {
                        // startStopHandler(data[dataIndex]);
                        // setCurrentRecord(data[dataIndex]);
                        // setShow2fa(true);
                        if (data[dataIndex]?.is2FAVerified === false) {
                            setCurrentRecord(data[dataIndex]);
                            setShow2fa(true);
                        } else {
                            startStopHandler(data[dataIndex]);
                        }
                    }
                    } isEnable={data[dataIndex]?.isRecurring ?  true : false}/>
                    // <button
                    //     style={{ background: !data[dataIndex]?.isRecurring ? COLORS.pimary : "#f40f00", color: '#ffffff', padding: '10px', borderRadius: '8px', border: 'none',cursor:isLoading ? "":"pointer" }}
                    //     onClick={() => startStopHandler(data[dataIndex])}
                    //     disabled={isLoading}
                    // >
                    //     {/* {(isLoading && data[dataIndex]) && <Spinner animation={"border"} size="sm" style={{marginRight:"5px"}}/> } */}
                    //     { !data[dataIndex]?.isRecurring ?  "Start" : "Stop"}

                    // </button>
                }
            },
        },
    ];

    const handleSendOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        setLoadingOTP(true);
        const payload = {
          phoneNo: userPhoneNo,
        };
        dispatch(SendOTP(payload)).then(() => {
          setLoadingOTP(false);
          setShowOTPInput(true);
          setOtpBtnText("Resend");
        });
      };

    const verifyOTPLengthOnBlur = (e: any) => {
        if (e.length < 6) {
          setOtpErrorMsg("Enter all otp digits");
        } else {
          setOtpErrorMsg("");
        }
      };

    const startStopHandler = async (data: any) => {
        try {
            setIsLoading(true)
            // console.log("startStopHandler =>", data);
            const payload = { id: data._id, status: !data.isRecurring, userId: userId, authCode: data?.is2FAVerified ? "ADMIN" : (otp || code) }
            const res = await dispatch(updatePaymentStatus(payload))
            await getRecPayments();
            // console.log(res)
            setIsLoading(false)
            setShow2fa(false);
        } catch (error) {
            setIsLoading(false)
        }
        setCode("");
        setOtp("");
        setShowOTPInput(false);
    }

    const renderIcon = (icon: string) => <img src={icon} alt="" />
    const customIcons: any = {
        SearchIcon: () => renderIcon(SearchFilter),
        PrintIcon: () => renderIcon(PrintFilter),
        DownloadIcon: () => renderIcon(DownloadFilter),
        ViewColumnIcon: () => <CiViewColumn style={{ color: 'black', fontSize: "larger" }} />,
        FilterIcon: () => renderIcon(FilterLines),
      }

    return (
        <>

            {/* 2FA Modal */}
            <Modal
                show={show2fa}
                onHide={toggle2faHandle}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader onClose={() => setShow2fa(false)}/>
                <Modal.Body className="sendFund-2FA-modal">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <div className="mb-2">
                    <img src={PaymentVerifictionIcon} width='100' height='100'/>
                    </div>
                    <div className="paymentVerifyText mb-3">
                    <h2 className="ver-right-title m-0">{t("sendFunds.modal.title")}</h2>
                    </div>
                </div>

                <Accordion>
                    <Accordion.Item eventKey="0" className="p-0 accordionTab">
                    <Accordion.Header>Verify 2FA from Authenticator App</Accordion.Header>
                        <Accordion.Body>
                            <form
                                className="verification-form"
                                // onSubmit={handleVerifyPaymentUpdated}
                                >
                                <InputTypeText
                                    placeholder="Enter code"
                                    onChange={(e) => {
                                    setCode(e.target.value);
                                    setTwofaErr("");
                                    }}
                                    errorMsg={twofaErr}
                                />
                                <BasicButton
                                    onClick={() => {
                                        startStopHandler(currentRecord)
                                    }}
                                    // type="submit"
                                    text="Send"
                                    isLoading={isLoading ? true: false}
                                />
                                </form>
                            </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion>
                    <Accordion.Item eventKey="0" className="mt-4 p-0 accordionTab">
                        <Accordion.Header>{t("verify.otp.description")}</Accordion.Header>
                        <Accordion.Body>
                        <div className="mr-xl-1">
                        <p>We will send OTP to your registered number</p>
                        </div>

                        <div className="mr-xl-1">
                        <CustomIconButton 
                            text={
                            loadingOTP ? <>
                            {" "}
                                Sending.. <Spinner animation={"border"} />
                            </>:
                            (`${otpBtnText} ${userPhoneNo}`)
                            }
                            isLoading={loadingOTP?true:false}
                            disabled={loadingOTP ? true : false}
                            onClick={handleSendOTPButtonClick}
                            suffixIcon={<SmsIcon />}
                        />
                        </div>

                        {showOTPInput ? (
                        <>
                            <div
                            style={{
                                alignContent: "center",
                                width: "100%",
                                padding: 10,
                            }}
                            >
                            <OtpInput
                                value={otp}
                                valueLength={6}
                                onChange={onChange}
                                onBlur={verifyOTPLengthOnBlur}
                            />
                            <div className="mr-xl-1">
                                <BasicButton 
                                text={
                                    isLoading? 'Verifying':"Verify"
                                }
                                className="mt-2"
                                disabled={isLoading ? true : false}
                                onClick={() => startStopHandler(currentRecord)}
                                isLoading={isLoading?true:false}
                                />
                            </div>
                            </div>
                            <div className="mr-xl-1">
                            {/* <div
                                onClick={handleSendOTPVoiceButtonClick}
                                style={{ fontSize: 13 }}
                            >
                                {" "}
                                Having trouble Receiving SMS,{" "}
                                {loadingOTPVoice ? (
                                <Spinner
                                    style={{ width: 15, height: 15 }}
                                    animation={"border"}
                                />
                                ) : (
                                <CallIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                />
                                )}{" "}
                                call me instead.
                            </div> */}
                            </div>
                        </>
                        ) : (
                        <></>
                        )}
                    </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </Modal.Body>
            </Modal>
             <Modal
                show={showUpdateForm}
                onHide={() => setShowUpdateForm(!showUpdateForm)}
                backdrop="static"
                keyboard={false}
                size="lg"
                // className={show2FA ? 'd-none' : 'd-block'}
            >
                <ModalHeader title={t("sendFunds.title")} onClose={() => setShowUpdateForm(!showUpdateForm)} />
                <Modal.Body
                // ref={trandferMoneyModalRef}
                >
                    <FundsTransferUpdateForm record={selectedRecord} getRecPayments={getRecPayments} onClose={() => setShowUpdateForm(!showUpdateForm)}/>
                </Modal.Body>
            </Modal>
            {/* <Row> */}
            {/* Content area collomns */}
            {/* <Col sm={12} md={12} lg={12}> */}
            {/* <Card className="p-3 p-xl-5 my-3 my-xl-4"> */}
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={<p className="mb-0 recuringTbl">Recurring Payments</p>}
                    data={data}
                    columns={columns}
                    components={{ icons: customIcons }}
                    options={{
                        selectableRows: "multiple",
                        selectableRowsHideCheckboxes: true,
                        selectableRowsOnClick: false,
                        // fixedHeader:true
                        responsive: 'standard',
                        textLabels:{ 
                            body:{
                              noMatch:'Sorry, no matching records found'
                            }
                          },
                          customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                            <CustomPagination
                              count={count}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onChangePage={changePage}
                              setCurrentPage={setCurrentPage}
                            />
                          )
                    }}
                />
            </ThemeProvider>
            {/* </Card> */}
            {/* </Col> */}
            {/* </Row> */}
        </>
    );
};

